.galleryImagesBox {     
  column-count: 2;    
  column-gap: 0.3rem;
  padding-bottom: 2rem;
}

.galleryImagesBox > img {
 width: 100% !important;    
 margin-top: 0.3rem;
 border-radius: 0.5rem;
}

.sideMenuGallery {
  width: auto;
  height: 100%;
  padding: 10px;
  background-color: var(--red-light);

  ul {
    margin: 0;
  }

  li {
    margin: 0.2rem;

    a {
      text-decoration: none;
      color: inherit;
    }
  }
}

.menuSessionsList {
  display: flex;
  flex-direction: column;
  height:100%;
  justify-content: space-around;
  font-size: 1rem;
  font-weight: bold;
  color: var(--primary-ultralight);
  align-items: center;
  list-style-type: none;
  padding: 1.5rem;
}

.shadowSessions {
  flex: 1;
  height: 100%;
  background-color: lightgreen;
  padding: 10px;
  text-align: start;
  background-color: rgba(255, 255, 255, 0.5);
  color: #000;
}
