.sessionContainer {
  margin: 0 -5vw 0 -5vw;
  padding: 4rem 5vw 4rem 5vw;
}

.backgroundColor {
  background-color: var(--primary-light);
}

// .sessionImagesBox {
//   display: grid;
//   grid-template-columns: auto auto;
//   padding: 0.8rem;
//   gap: 10px;
// }

.sessionImagesBox {
   display:inline-block;    
   line-height: 0;       
   column-count: 2;    
   column-gap: 0.3rem; 
   margin-right: auto;    
   margin-left: auto;
}

.sessionImagesBox > img {
  width: 100% !important;    
  height: auto !important;
  padding: 0.15rem;
  border-radius: 0.5rem;
}

