/*
1920
1280
800
600
320
*/


/* Variables */
:root {
  /* --primary-color: #b08c59; */
  --primary-color: #a06615;
  /* --primary-light:#e2d4c0; */
  --primary-light:#e3c193; 
  --primary-ultralight: #EAE7DC;
  --red-color: hsl(360, 67%, 44%);
  --red-light: #E98074;
  --gray-color: #52514f;
  --gray-dark: #343a40;;
  --gray-light: #9a9996;
  --gray-ultralight: #c9c9c6;
  --white-color: #fff;
  --success-color: ;
  
  --radius: 0.25rem;
  --spacing: 0.25rem;
  --transition: all 0.3s linear;
}

/* Global */
body {
  margin: 0;
  margin-left: auto;
  margin-right: auto;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

div {
  box-sizing: border-box;
}

h1 {
  font-family: 'Dancing Script', cursive;
  font-weight: 400;
  color: var(--primary-color);
  font-size: 3rem;
  margin: 0;
  /* text-shadow: 2px 2px 2px #ffff; */
  text-shadow: 1px 1px #fff;
}

h2 {
  font-family: 'Dancing Script', cursive;
  font-weight: 400;
  color: var(--primary-color);
  font-size: 2rem;
  margin: 0;
  text-shadow: 1px 1px 3px #fff, -1px -1px 3px #fff;
}

.app {
  display: block;
  max-width: 1920px;
  position: relative;
  margin: auto;
  min-height: 100vh; /* will cover the 100% of viewport */
  text-align: center;
  /* overflow: hidden; */ 
  overflow: clip;
  /* padding-bottom: calc(2*var(--p1));  height of footer */
  padding-bottom: 8rem; /* height of footer */
}

.main {
  width: 90vw;
  max-width: calc(0.9*1920px); /* to make 90% of the app width if view is bigger than 1920px */
  margin: auto;
}

.app-link {
  color: #61dafb;
}


.global-shortExplanation {
  font-family: 'Tajawal', sans-serif;
  color: var(--primary-ultralight);
  font-size: 1.5rem;
}

.global-shortExplanation2 {
  font-family: 'PT Sans', sans-serif;
  /* color: var(--gray); */
  font-size: 1.1rem;
  font-weight: bold;
  margin: 1rem 0;
}

/* Button */
button {
  display: inline-block;
  border: none;
  margin: 1.5rem 0;
  text-decoration: none;
  background: none;
  /* color: var(--gray); */
  font-family: sans-serif;
  font-size: 1rem;
  cursor: pointer;
  text-align: center;
  transition: background 250ms ease-in-out, 
  transform 150ms ease;
  -webkit-appearance: none;
  -moz-appearance: none;
  text-transform: uppercase;
  transition: var(--transition);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
}

button:hover {
  background: var(--gray-light);
  color: var(--white-color);
}
  
button:active {
  transform: scale(0.99);
}

/* Forms */
.form .form-group {
  margin: 1.2rem 0;
  text-align: center;
}

.form .form-text {
  display: block;
  margin-top: 0.3rem;
  color: #888;
}
  
.form input[type='text'],
.form input[type='email'],
.form input[type='password'],
.form input[type='date'],
.form select,
.form textarea {
  display: block;
  width: 30vw;
  min-width: 200px;
  padding: 0.4rem;
  font-size: 1.2rem;
  border: 1px solid #ccc;
  margin: 0.5rem auto;
  box-sizing: border-box;
}
  
  .form input[type='submit'],
  button {
    font: inherit;
  }
  
  .table th,
  .table td {
    padding: 1rem;
    text-align: left;
  }
  
  .table th {
    background: var(--light-color);
  }

  @media (max-width: 860px)  {
    .main {
      width: 96vw;
    }
    
    .global-shortExplanation {
      font-size: 27px;
    }
    
    .global-shortExplanation2 {
      font-family: 'PT Sans', sans-serif;
      /* color: var(--gray); */
      font-size: 17px;
      font-weight: bold;
      margin: 10px 0;
    }
  }
  
  @media (min-width: 800px) {
    html {
      font-size: 20px;
    }
  }
  @media (min-width: 1280px) {
    html {
      font-size: 25px;
    }
  }

