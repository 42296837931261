.homeContainer {
  margin: auto;
}

// AboutMeShort
.aboutMeContainer {
  margin: 3rem 0;
}

.aboutMeImage {
  display: inline-block;
  width: 40vw;
  max-width: calc(0.25*1920px);
  min-width: 250px;
  padding-top: 1.5rem;
  margin: 0 1rem;
  opacity: 0;
  visibility: hidden;
  transition: opacity 1200ms ease-out, transform 600ms ease-out,
  visibility 1200ms ease-out;
  transform: translateY(10vh);
  will-change: opacity, transform, visibility;
}

.isVisible {
  opacity: 1;
  transform: none;
  visibility: visible;
}

.abutMeTextDiv {
  display: inline-block;
  max-width: 45%;
  min-width: 200px;
  padding: 1rem;
  text-align: left;
  vertical-align: top;
}

.aboutMeButton {
  color: var(--gray-color);
}

.aboutMeButton:hover {
  color: var(--white-color);
}

@media (max-width: 800px) {
  .abutMeTextDiv {
    width: 90%;
    max-width: 250px;
    padding: 0.5rem;
  }
}

@media (max-width: 600px) {
  .abutMeTextDiv {
    width: 90%;
    max-width: 400px;
    padding: 0.5rem;
  }
}


// AdvertSession

.shortSessionButton {
  border-color: var(--primary-ultralight);
  color: var(--white-color);
}

.shortSessionButton:hover {
  background-color: var(--white-color);
  color: var(--gray-dark);
}


// ShortGallery
.shortGalleryContainer {
  margin: 0;
}

.imageGrid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: 1fr;
  margin-bottom: 0rem;
}
  
.imageGrid::before {
  content: '';
  width: 0;
  padding-bottom: 100%;
  grid-row: 1 / 1;
  grid-column: 1 / 1;
}

.imageGrid > *:first-child {
  grid-row: 1 / 1;
  grid-column: 1 / 1;
}

.shortGalleryButton {
    color: var(--gray-color);
    margin: 2.5rem auto;
}

.shortGalleryButton:hover {
    background-color: var(--gray-light);
    color: var(--white-color);
}

@media (max-width: 860px) {
  .imageGrid {
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-rows: 1fr;
  }
}

@media (max-width: 600px) {
  .shortGalleryContainer {
    margin: 0 1rem;
  }
}


// Slideshow

.slideShow {
  margin: 0 -5vw;
}

.slide {
  display: inline-block;
  width: 100%;
}

.second {
  transform: translateX(-100%);
  animation: fadeIn 3s;
  -webkit-animation: fadeIn 3s;
  -moz-animation: fadeIn 3s;
  -o-animation: fadeIn 3s;
  -ms-animation: fadeIn 3s;
}

@keyframes fadeIn {
  0% {opacity:0;}
//   80% {opacity:1;}
  100% {opacity:1;}
}

@-moz-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@-webkit-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@-o-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@-ms-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@media (max-width: 860px ) { 
  .slideShow {
    margin: 0 -250px 0 -400px;
  }
}

// ShortSessions
.shortSession {
    background-color: var(--primary-light);
    margin: 0 -5vw 0 -5vw;
    padding: 3rem 5vw 3rem 5vw;
}

.shortSessionGallery {
  display: flex;
  flex-direction: row;
  gap: 0.7rem;
  margin: 2rem 0;
}

.column {
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 0.7rem;
}

.imageItem {
  transition: transform 0.3s;
}

.imageItem img {
  width: 100%;
  border-radius: 0.5rem;
  object-fit: cover;
  color: var(--gray-dark);
}

.imageItem:hover {
 transform: translateY(-0.5rem);
 cursor: pointer;
}

.advertSession {
  color: var(--gray-dark);
}

// Contact

.contactButton {
  color: var(--gray-dark);
}
