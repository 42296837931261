/* Posts Page */
.postForm .post-form-header {
  background: var(--primary-color);
  padding: 0.5rem;
}

.postItem {
  display: grid;
  grid-template-columns: 1fr 4fr;
  grid-gap: 3rem;
  // align-items: center;
  border-top: 0.1rem solid var(--gray-ultralight);
  padding: 1.5rem 0;
  text-align: left;

  h4 {
    color:  var(--gray-ultralight);
  }

  h2 {
    color: var(--gray-dark)
  }
}

.postContainer {
  padding: 2rem 1rem;
  text-align: left;
}
// .postItem > div:first-child {
//   text-align: center;
// }

// .postLink {
//   display: block;
//   margin-left: 80%;
// }

.postButtons {
  display: flex;
  justify-content: end
}

.postItem img {
  width: 100px;
}

.postButton {
  color: var(--gray-light);
}

.postButton:hover {
  background-color: var(--gray-ultralight);
  color: var(--white-color);
}

// .postItem .comment-count {
//   background: var(--light-color);
//   color: var(--primary-color);
//   padding: 0.1rem 0.2rem;
//   border-radius: 5px;
//   font-size: 0.8rem;
// }

// .postItem .post-date {
//   color: #aaa;
//   font-size: 0.8rem;
//   margin-bottom: 0.5rem;
// }

@media (max-width: 800px) {
  .postItem {
    display: block
  }
}